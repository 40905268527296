import JSON_BIG from '~/lib/json-bigint';
import { TimerType } from '~/models/utils-types';
import { REG_HEX_COLOR, REG_MATCH_HEX } from '~/utils/reg';
import API_GLOBAL from '~/api/global';
import { AxiosProgressEvent } from 'axios';
import qs from 'qs';
import { LocationQuery } from 'vue-router';
import { useCloned } from '@vueuse/core';
import { unref } from 'vue';

export const JsonBig = JSON_BIG({
  storeAsString: true
});

// 补全16进制色值
export const completionHexColor = (color: string) => {
  if (!REG_HEX_COLOR.test(color)) {
    return '#ffffff';
  }
  // 将字符串转为小写 并 删除#号
  const s = color.toLowerCase().replace(/#/, '');
  // 需要拼接字符串 每两位加一个逗号 f00 => #ff0000
  return (s.length === 3) ? `#${s.split('').map(i => `${i}${i}`).join('')}` : `#${s}`;
};

// 16进制转RGB
export const hexToRGB = (color: string) => {
  // 判断是否为3位16进制或者6位16进制 若都不是 则返回白色
  if (!REG_HEX_COLOR.test(color)) {
    return [255, 255, 255];
  }
  // 将字符串转为小写 并 删除#号
  const s = color.toLowerCase().replace(/#/, '');
  // 需要拼接字符串 每两位加一个逗号 f00|ff0000 => ff,00,00
  const ns = s.length === 3 ? s.split('').map(i => `${i}${i}`).join(',') : s.replace(REG_MATCH_HEX, ',');
  // 循环十六进制字符串 并 转换为十进制数字
  return ns.split(',').map(i => parseInt(`0x${i}`));
};

// RGB转16进制
export const rgbToHex = (rgb: number[]) => {
  const hexArr = rgb.map(num => {
    const hex = num.toString(16);
    return `00${hex || 0}`.substring(hex.length);
  });
  return `#${hexArr.join('')}`;
};

// 16进制色值转浅色
export const hexToLight = (color: string, opacity: number) => {
  // 16进制转rgb
  const rgb = hexToRGB(color);
  // rgb转浅色
  const rgbToLight = rgb.map(num => Math.floor((255 - num) * opacity + num));
  // rgb转hex
  return rgbToHex(rgbToLight);
};

// 防抖
export const debounce = (callback: () => void, delay: number) => {
  let timer: TimerType = null;
  return function (this: unknown, ...args: any) {
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }
    timer = setTimeout(() => {
      callback.apply(this, args);
    }, delay);
  };
};

// 截流
export const throttle = (callback: () => void, delay: number) => {
  let time = 0;
  return function (this: unknown, ...args: any) {
    const now = +new Date();
    if (now - time > delay) {
      callback.apply(this, args);
      time = now;
    }
  };
};

// 去除字符串空格
export const trimStringSpace = (str: string) => str.replace(/\s/g, '');

/**
 *@desc 判断字段转化为boolean的非空判断
 *@author HTR
 *@date 2023/02/51 12:27:22
 *@param  {any} params 需要判断的参数
 *@param  {boolean} includesNumber 是否需要将number 0进行转换
 *@return {boolean}
 */
export const covertParams = (params: unknown, includesNumber = false) => {
  const type = typeof params;
  if (type === 'string') {
    return trimStringSpace(params as string);
  }
  if (type === 'number') {
    if (includesNumber && params === 0) {
      return true;
    }
    // 判断是否是NaN
    if (isNaN(params as number)) {
      return false;
    }
  }
  if (type === 'object') {
    // 否是空数组
    if (Array.isArray(params)) {
      return params.length > 0;
    }
    // 是否是空对象
    if (JSON.stringify(params) === '{}') {
      return false;
    }
  }
  return params;
};
type AnchorType = {
  // 目标dom
  target: HTMLElement | null;
  // 容器dom
  container: HTMLElement | null;
  // 速度 默认为10 值越大越慢
  speed?: number;
  // 偏移量
  offset?: number;
  // 完成回调
  complete?: () => void
}
// 滚动动画
export const scrollAnchor = () => {
  let animtionTimer = 0;
  // 动画停止函数
  const cancel = (complete?: AnchorType['complete']) => {
    cancelAnimationFrame(animtionTimer);
    complete?.call(void 0);
  };
  // 动画执行函数
  return function (options: AnchorType) {
    const { target, container, speed = 10, offset = 0, complete } = options;
    if (!target || !container) {
      return;
    }
    // 如果不支持采用animation
    const requestAnimationFrame = window.requestAnimationFrame ||
          window.mozRequestAnimationFrame ||
          window.webkitRequestAnimationFrame ||
          window.msRequestAnimationFrame;
    // 每次执行前先停止动画
    cancelAnimationFrame(animtionTimer);
    // 元素所在位置
    const ts = target.offsetTop + offset;
    // 当前容器滚动条位置
    const { scrollTop: mainScrollTop, clientHeight, scrollHeight } = container;
    // 判断是否满足动画滚动差值
    if (mainScrollTop === ts) {
      complete?.call(void 0);
      return;
    }
    // 判断方向 dir ?  上 : 下
    const dir = mainScrollTop > ts;
    // 记录当前和下一次的滚动高度
    const nextScrollTop = { now: 0, next: 0 };
    // 开始执行
    animtionTimer = requestAnimationFrame(function loop () {
      nextScrollTop.now = Math.ceil(container.scrollTop);
      // 获取当前和下一次的滚动高度
      const { now, next } = nextScrollTop;
      // 缓动计算
      const value = (ts - now) / speed;
      const scrollValue = value > 0 ? Math.ceil(value) : Math.floor(value);
      // 动画停止条件
      if (next !== 0 && now !== next) {
        cancel(complete);
        return;
      }
      const offsetStop = dir ? (now > ts) : (now < ts);
      const stop = dir ? now <= 0 : (now + clientHeight) >= scrollHeight;
      if (offsetStop) {
        const scrollTopVal = now + scrollValue;
        container.scrollTop = scrollTopVal;
        // 记录下一次的值
        nextScrollTop.next = scrollTopVal;
        if (stop) {
          container.scrollTop = ts;
          cancel(complete);
          return;
        }
        animtionTimer = requestAnimationFrame(loop);
      } else {
        container.scrollTop = ts;
        cancel(complete);
      }
    });
  };
};

// 获取FormData
export const getFormData = (params: Record<string, string | File>) => {
  const keys = Object.keys(params);
  const formData = new FormData();
  for (let i = 0; i < keys.length; i += 1) {
    const key = keys[i];
    formData.append(key, params[key]);
  }
  return formData;
};

// 上传文件
type globalUploadFileSign = (
  params: Record<string, string | File>,
  uploadProgress?: (event: AxiosProgressEvent) => void
) => Promise<string>;
export const globalUploadFile: globalUploadFileSign = async (params, uploadProgress) => {
  const formData = getFormData(params);
  const { url } = await API_GLOBAL.globalUpload(formData, uploadProgress);
  return url;
};

// 日期转换
export const getDateFormat = (date: Date, format: string) => {
  let result = format;
  const formatDic: Record<string, number> = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds()
  };
  const yearReg = new RegExp('(y+)', 'i');
  if (yearReg.test(format)) {
    const yearExec = yearReg.exec(format);
    if (yearExec && yearExec[0]) {
      const year = result.replace(yearExec[0], `${date.getFullYear()}`.substring(4 - yearExec[0].length));
      result = year;
    }
  }
  const keys = Object.keys(formatDic);
  for (let i = 0; i < keys.length; i += 1) {
    const reg = new RegExp(`(${keys[i]})`);
    if (reg.test(format)) {
      const exec = reg.exec(format);
      if (exec && exec[0]) {
        const value = `${formatDic[keys[i]]}`;
        const replace = result.replace(exec[0], exec[0].length <= 1 ? value : `00${value}`.substring(value.length));
        result = replace;
      }
    }
  }
  return result;
};

// 下载二进制文件
export const downloadBlobFile = (fileName: string, data: BlobPart, fileType?: string) => {
  const windowURL = window.URL || window.webkitURL;
  const blob = new Blob([data], { type: fileType });
  const url = windowURL.createObjectURL(blob);
  const a = document.createElement('a');
  a.download = fileName || 'file';
  a.href = url;
  a.dispatchEvent(new MouseEvent('click'));
  windowURL.revokeObjectURL(url);
};

// 下载文件
export const downloadFile = (url: string, done?: () => void) => {
  const iframe = document.createElement('iframe');
  iframe.src = url;
  iframe.style.display = 'none';
  document.body.appendChild(iframe);
  setTimeout(() => {
    document.body.removeChild(iframe);
    if (done) {
      done();
    }
  }, 500);
};

// 下载图片
export const downloadImage = (src: string, name?: string) => {
  const image = new Image();
  // 获取后缀
  const type = src.substring(src.lastIndexOf('.') + 1);
  // 解决跨域 Canvas 污染问题
  image.setAttribute('crossOrigin', 'anonymous');
  // 图片加载完成
  image.onload = () => {
    // 获取图片宽高
    const { width, height } = image;
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    const context = canvas.getContext('2d');
    if (context) {
      context.drawImage(image, 0, 0, width, height);
      const url = canvas.toDataURL(`image/${type}`);
      const a = document.createElement('a');
      a.download = name || 'image';
      a.href = url;
      a.dispatchEvent(new MouseEvent('click'));
    }
  };
  image.src = src;
};

// 字符串转对象
JSON.tsParse = <T = any>(str: string): T => {
  try {
    return JSON.parse(str);
  } catch (error) {
    return {} as T;
  }
};

// 处理参数中带有链接的路由
type encodeingUrlSign = (
  data: {
    // 控制台链接
    url: string;
    // 携带参数
    query?: RouterQuery;
  }
) => string;
// 链接参数类型
type RouterQuery = Record<string, string | number>;
export const encodeingUrl: encodeingUrlSign = ({ url, query: paramsQuery }) => {
  const containQuery = url.split('?');
  // 获取路由路径
  const path = containQuery.shift();
  // 路由需要携带的参数
  const query: RouterQuery = paramsQuery || {};
  for (let i = 0; i < containQuery.length; i++) {
    const queryItem: RouterQuery = qs.parse(containQuery[i]);
    // 迭代对象是否值中含有http
    Object.entries(queryItem).forEach(item => {
      const [key, value] = item;
      query[key] = `${value}`.includes('http') ? window.btoa(`${value}`) : value;
    });
  }
  return `${path}?${qs.stringify(query)}`;
};

// 获取encodeingUrl中的参数
export const getEncodeingUrlQuery = (routeQuery: LocationQuery) => {
  const queryClone = unref(useCloned(routeQuery).cloned);
  const querys: LocationQuery = {};
  Object.entries(queryClone).forEach(item => {
    const [key, value] = item;
    try {
      const decodeVal = window.atob(`${value}`);
      if (decodeVal.includes('http')) {
        queryClone[key] = decodeVal;
      } else {
        querys[key] = value;
      }
    } catch (error) {
      queryClone[key] = value;
      querys[key] = value;
    }
  });
  const querysJoin = Object.entries(querys).length ? qs.stringify(querys) : '';
  return { ...queryClone, querysJoin } as LocationQuery;
};


// 文件大小单位转换
export const byteConvert = (bytes: number | string) => {
  if (isNaN(Number(bytes))) {
    return '';
  }
  if (bytes === 0) {
    return '0 KB';
  }
  const symbols = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  let exp = Math.floor(Math.log(Number(bytes)) / Math.log(2));
  if (exp < 1) {
    exp = 0;
  }
  const i = Math.floor(exp / 10);
  let covertBytes = Number(bytes) / Math.pow(2, 10 * i);

  if (bytes.toString().length > covertBytes.toFixed(2).toString().length) {
    covertBytes = +covertBytes.toFixed(2);
  }
  return covertBytes + symbols[i];
};

// 获取产品点击链接
export const getProductIntoduceLink = (buyUrl: string) => {
  if (+buyUrl !== 0 && buyUrl !== 'null') {
    return buyUrl;
  }
  return '/goods';
};

// 获取UTC时间
export const getUTCDate = () => {
  const date = new Date();
  const y = date.getUTCFullYear();
  const m = date.getUTCMonth();
  const d = date.getUTCDate();
  const h = date.getUTCHours();
  const M = date.getUTCMinutes();
  const s = date.getUTCSeconds();
  return `${y}-${m + 1}-${d}T${h}:${M}:${s}Z`;
};

// 将对象转为labelValue格式
export const transferObjOptions = (obj: Record<string, string | number>) => {
  const arr = Object.entries(obj);
  return arr.map(([value, label]) => ({ label: `${label}`, value }));
};
// 去除空值属性
export const removeEmptyAttr = <T extends object>(obj: T) => {
  const clone = JSON.tsParse<T>(JSON.stringify(obj));
  for (const key in clone) {
    if (!covertParams(clone[key], true)) {
      Reflect.deleteProperty(clone, key);
    }
  }
  return clone;
};

// 加载图片
export const loadImage = (img: string) => {
  return new Promise<boolean>(resolve => {
    const image = new Image();
    image.src = img;
    if (image.complete) {
      resolve(true);
      return;
    }
    image.onload = () => {
      resolve(true);
    };
  });
};

// 加载所有图片
export const loadAllImage = (list: string[]) => {
  return new Promise<boolean[]>(resolve => {
    const loadList = list.map(item => loadImage(item));
    Promise.all(loadList).then(res => resolve(res));
  });
};

// 添加link标签
export const addLinkElement = (url: string) => {
  const link = document.createElement('link');
  link.type = 'text/css';
  link.rel = 'stylesheet';
  link.href = url;
  const head = document.getElementsByTagName('head')[0];
  head.appendChild(link);
};

// 获取查询链接
type GetQueryLinkParams = { path: string, query: Record<string, string | number | undefined | null> };
type GetQueryLinkSign = (params: GetQueryLinkParams) => string;
export const getQueryLink: GetQueryLinkSign = params => {
  const { path, query } = params;
  const keys = Object.keys(query);
  const queryArr = [];
  for (let i = 0; i < keys.length; i += 1) {
    const key = keys[i];
    const data = query[key];
    if (data) {
      queryArr.push(`${key}=${data}`);
    }
  }
  return `${path}?${queryArr.join('&')}`;
};
